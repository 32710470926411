import React from 'react';
import PropTypes from 'prop-types';

import { GlobalSavedWebPagesCount } from '../boxouts';
import { WaybackMachineLogoWithLink } from '../logo';
import Config from '../../config';

import SearchInput from './search-input';
import { DonationLink } from '../donation';
import { connect } from 'react-redux';
import {
  getAutoSuggestionInProgress,
  getAutoSuggestionList,
  hasAutoSuggestionList,
  isAutoSuggestionInvalid
} from '../../selectors/ui/hosts';
import { fetchSuggestionsForImmediateQuery } from '../../reducers/entities/hosts';
import {
  updateSearchInputFieldText,
  updateSearchQuery,
  updateSearchTextByImmediateText
} from '../../reducers/ui/search-request';
import { getImmediateQueryText } from '../../selectors/ui/search-request';

const ConnectedSearchInput = connect(
  (state, props) => ({
    autoSuggestionInvalid: isAutoSuggestionInvalid(state, props),
    autoSuggestionInProgress: getAutoSuggestionInProgress(state, props),
    autoSuggestionList: getAutoSuggestionList(state, props),
    hasAutoSuggestion: hasAutoSuggestionList(state, props),

    text: getImmediateQueryText(state, props)
  }),
  (dispatch, props) => ({
    onFetchSuggestions: () => dispatch(fetchSuggestionsForImmediateQuery()),
    onSelectSearchOption: (text) => {
      dispatch(updateSearchInputFieldText(text));
      dispatch(updateSearchQuery(text));
    },
    onUpdateSearchText: (text) => {
      dispatch(updateSearchInputFieldText(text));
      if (Config.search.immediate && text.length >= Config.search.query_min_length) {
        dispatch(updateSearchQuery(text));
      }
    },
    onUpdateSearchTextByImmediateText: () => dispatch(updateSearchTextByImmediateText())
  })
)(SearchInput);

const SearchToolbarLayout = ({
  // dirty hack to change "origin" parameter of donation link depending on
  // the type of the current view.
  selectedView
}) => {
  const donationLinkOrigin = selectedView ? "wbwww-CalndrDonateButton" :
	"wbwww-HomeDonateButton";
  return (
    <div className="search-toolbar">
      <DonationLink linkStyle="button" origin={donationLinkOrigin}/>
      <div className="search-toolbar-logo-container">
        <WaybackMachineLogoWithLink/>
      </div>
      <div className="search-text-container">
      <GlobalSavedWebPagesCount/>
      <ConnectedSearchInput selectedView={selectedView}/>
      </div>
    </div>
  );
};

SearchToolbarLayout.displayName = 'SearchToolbarLayout';

SearchToolbarLayout.propTypes = {
  selectedView: PropTypes.string
};

export default React.memo(SearchToolbarLayout);
